import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import './Home.css';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { AnimatePresence, motion } from 'framer-motion';

const customStyles = {
  content: {
    width: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
    boxShadow: `20px 20px 30px #000`,
  },
};

Modal.setAppElement('#root');

function Home() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Open/close modal
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <AnimatePresence initial={false}>
      <div className="home-container">
        <section className="image-container">
          <motion.h1
            initial={{ x: 100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: { type: 'spring', bounce: 0.8, duration: 3 },
            }}
            className="blue-light heading-home"
          >
            Yoga Motion
          </motion.h1>
          <div className="blue-light bottom">
            <motion.p
              whileInView={{
                x: 0,
                opacity: [0, 1],
                scale: [0, 1],
                transition: { type: 'spring', bounce: 0.8, duration: 3 },
              }}
              className="blue-light"
            >
              Your body works best when when everything is connected and working
              together, that’s where Yoga Motion comes in. All classes are
              thoughtfully designed to improve health, strength, balance and
              focus while relieving pain and stress. We’re all a work in
              progress! So let’s get to work{' '}
            </motion.p>
            <Link to={'/sub-page'} className="btn btn-animated btn-white ">
              Become a Member
            </Link>
          </div>
        </section>
        <div className="divider-banner">
          <img
            className=" "
            src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1660252145/1769669-cropped_g177jy.svg"
            alt=""
          />
        </div>

        <section className="image-container-classes ">
          <div className="blue-light left">
            <p>
              Keep your practice up <br /> with our{' '}
              <motion.span
                whileInView={{
                  x: 0,
                  opacity: [0, 1],
                  scale: [0, 1],
                  transition: { type: 'spring', bounce: 1, duration: 2 },
                }}
                className="orange"
              >
                daily
              </motion.span>{' '}
              <br />
              in{' '}
              <motion.span
                whileInView={{
                  x: 0,
                  opacity: [0, 1],
                  scale: [0, 1],
                  transition: { type: 'spring', bounce: 1, duration: 2 },
                }}
                className="orange"
              >
                person
              </motion.span>{' '}
              or <br />
              <motion.span
                whileInView={{
                  x: 0,
                  opacity: [0, 1],
                  scale: [0, 1],
                  transition: { type: 'spring', bounce: 1, duration: 2 },
                }}
                className="orange"
              >
                Zoom
              </motion.span>{' '}
              classes
            </p>
            <Link to={'/events'} className="btn btn-animated btn-white ">
              Book a Class
            </Link>
          </div>
        </section>
        <div className="divider-banner">
          <img
            className=" "
            src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1660252145/1769669-cropped_g177jy.svg"
            alt=""
          />
        </div>
        <section className="image-container-videos">
          <div className="blue-light videos-bottom">
            <p>
              {' '}
              Too busy! No{' '}
              <motion.span
                whileInView={{
                  x: 0,
                  opacity: [0, 1],
                  scale: [0, 1],
                  transition: { type: 'spring', bounce: 1, duration: 2 },
                }}
                className="orange"
              >
                problem
              </motion.span>
              <br />
              check out our
              <br />
              <motion.span
                whileInView={{
                  x: 0,
                  opacity: [0, 1],
                  scale: [0, 1],
                  transition: { type: 'spring', bounce: 1, duration: 2 },
                }}
                className="orange"
              >
                YouTube
              </motion.span>{' '}
              channel
            </p>
            <Link to={'/video-library'} className="btn btn-animated btn-white ">
              Watch Class
            </Link>
          </div>
        </section>
        <div className="icon-banner">
          <div>
            <motion.a
              whileInView={{
                x: 0,
                opacity: [0, 1],
                scale: [0, 1],
                transition: { type: 'spring', bounce: 1, duration: 2 },
              }}
              href="https://www.facebook.com/yogamotiontbay"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1664892662/123472531557740373-128_kra3er.png"
                alt=""
              />
            </motion.a>
          </div>
          <div>
            <motion.a
              whileInView={{
                x: 0,
                opacity: [0, 1],
                scale: [0, 1],
                transition: { type: 'spring', bounce: 1, duration: 2 },
              }}
              href="https://www.youtube.com/watch?v=ooKuk5AADjg&list=PLPIuYXaGFn2pdzN_ED9FGUf0YVaEMrjqN"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1664893120/14578992801599781001-128_bvsvnv.png"
                alt=""
              />
            </motion.a>
          </div>
          <div>
            <motion.a
              whileInView={{
                x: 0,
                opacity: [0, 1],
                scale: [0, 1],
                transition: { type: 'spring', bounce: 1, duration: 2 },
              }}
              href="https://www.instagram.com/yogamotionn/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1664893394/6590558241561032669-128_etjwuy.png"
                alt=""
              />
            </motion.a>
          </div>
          <div>
            <motion.a
              whileInView={{
                x: 0,
                opacity: [0, 1],
                scale: [0, 1],
                transition: { type: 'spring', bounce: 1, duration: 2 },
              }}
              href="https://twitter.com/yogamotiontbay"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1664893562/7723321031557740373-128_anbijb.png"
                alt=""
              />
            </motion.a>
          </div>
        </div>
        <div className="footer">
          <div className="row">
            <div className="first-column">
              <h2>Yoga Motion</h2>

              <a
                className="a-tag"
                rel="noreferrer"
                target="_blank"
                href="https://www.google.ca/maps/place/105+May+St+N,+Thunder+Bay,+ON+P7C+3N9/@48.3843509,-89.2470849,17.25z/data=!4m5!3m4!1s0x4d591f4fad94a38b:0x874ac7a1840524a!8m2!3d48.3844423!4d-89.2457506?hl=en"
              >
                105 North May St
              </a>
              <p>Thunder Bay, On</p>

              <a className="a-tag" href="tel:807-633-6739">
                807-633-6739
              </a>
              <br />
              <a className="a-tag" href="mailto:yogamotion12@gmail.com">
                yogamotion12@gmail.com
              </a>
            </div>
            <div className="second-column">
              <h2>Quick links</h2>
              <ul>
                <li>
                  <Link className="a-tag" onClick={openModal}>
                    About Us
                  </Link>{' '}
                </li>
                <li>
                  <Link className="a-tag" to={'/events'}>
                    Book A Class
                  </Link>{' '}
                </li>
                <li>
                  <Link className="a-tag" to={'/sub-page'}>
                    Become A Member
                  </Link>{' '}
                </li>
                <li>
                  <Link className="a-tag" to={'/video-library'}>
                    Video Library
                  </Link>{' '}
                </li>
              </ul>
            </div>
            <div className="third-column">
              <h2>Yoga Motion &copy; 2015</h2>
            </div>
          </div>
        </div>
        <Modal
          className="modal"
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="About Us"
        >
          <h2>About Us</h2>
          <button className="home-modal-btn-close" onClick={closeModal}>
            X
          </button>

          <h2>Yoga Motion</h2>
          <p>
            Your body works best when all its parts work together
            synergistically, that’s where Synergy Flow comes in. Synergy flow is
            a mindfulness based holistic yoga practice that is thoughtfully
            designed to improve health, strength, balance, focus and relieve
            pain and stress. We’re all a work in progress! So let’s get to work!
          </p>
        </Modal>
      </div>
    </AnimatePresence>
  );
}

export default Home;
