import React, { useState } from 'react';
import './CreateEvent.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createEvent } from '../../features/events/eventSlice';

function CreateEvent() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('open');
  const [instructor, setInstructor] = useState('');
  const [dayTime, setDayTime] = useState('');
  const [imageURL, setImageUrl] = useState(
    'https://res.cloudinary.com/dkiqkafgo/image/upload/v1639605426/logo-1_zxjktj.jpg'
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createEvent({
        title,
        description,
        status,
        instructor,
        dayTime,
        imageURL,
      })
    );
    navigate('/events');
    toast.success('New class created!');
  };

  return (
    <>
      <div className="create-event-container">
        <section className="heading">
          <h1>Create New Event</h1>
          <p>Please fill out the form below</p>
        </section>

        <section className="form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="title">Class Title</label>
              <input
                type={'text'}
                name="title"
                id="title"
                className="form-control"
                placeholder="Class Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description of the issue</label>
              <textarea
                rows="4"
                cols="50"
                name="description"
                id="description"
                className="form-control"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="imageURL">Image URL</label>
              <input
                type={'text'}
                name="imageURL"
                id="imageURL"
                className="form-control"
                placeholder="Image URL"
                value={imageURL}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="instructor">Class Instructor</label>
              <input
                type={'text'}
                name="instructor"
                id="instructor"
                className="form-control"
                placeholder="Instructor"
                value={instructor}
                onChange={(e) => setInstructor(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="dayNumber">Day #</label>
              <input
                type={'datetime-local'}
                name="dayTime"
                id="dayTime"
                className="form-control"
                placeholder="Day #"
                value={dayTime}
                onChange={(e) => setDayTime(e.target.value)}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-block">Submit</button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}

export default CreateEvent;
