import axios from 'axios';

const API_URL = 'https://yoga-motion-studio.herokuapp.com/api/subs/';

// Get user subscriptions
const getSubscriptions = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + 'subscription-status', config);

  console.log(response.data);

  return response.data;
};

// Get stripe prortal
const getStripePortal = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + 'customer-portal', config);

  window.open(response.data);

  return response.data;
};

// Get user subscriptions
const getSubs = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + 'prices', config);

  console.log(response);
  return response.data;
};

const subscriptionService = {
  getSubscriptions,
  getStripePortal,
  getSubs,
};

export default subscriptionService;
