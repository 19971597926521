import axios from 'axios';

const API_URL = 'https://yoga-motion-studio.herokuapp.com/api/events/';

// Get class bookings
const getBookings = async (eventId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + eventId + '/booking', config);

  return response.data;
};

// Get class bookings
const getAllBookings = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    'http://localhost:5000/api/all-bookings/',
    config
  );

  return response.data;
};

// Create ticket note
const createBooking = async (bookingText, eventId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL + eventId + '/booking',
    {
      text: bookingText,
    },
    config
  );

  return response.data;
};

// Delete booking

const deleteBooking = async (bookingId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + bookingId + '/booking', config);

  console.log(response.data);

  return response.data;
};

const bookingService = {
  deleteBooking,
  getBookings,
  getAllBookings,
  createBooking,
};

export default bookingService;
