import axios from 'axios';

const API_URL = 'https://yoga-motion-studio.herokuapp.com/api/subs/';

// Get all subs
const getSubs = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + 'prices', config);

  console.log(response.data);
  return response.data;
};

const priceService = { getSubs };

export default priceService;
