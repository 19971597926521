import React from 'react';
import { Link } from 'react-router-dom';
import './StripeCancelPage.css';

function StripeCancelPage() {
  return (
    <div className="stripe-cancel">
      <h1>Payment Cancelled!</h1>
      <h2>No payments have been charged to you credit card</h2>
      <Link className="stripe-cancel-link" to={'/profile'}>
        View Profile
      </Link>
      <p>or</p>
      <Link className="stripe-cancel-link" to={'/sub-page'}>
        Purchase Subscription
      </Link>
    </div>
  );
}

export default StripeCancelPage;
