import axios from 'axios';
import './SubPage.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubs } from '../../features/prices/priceSlice';

import Spinner from '../../components/Spinner/Spinner';

function SubPage() {
  const { user } = useSelector((state) => state.auth);
  const { prices } = useSelector((state) => state.prices);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(user);
    dispatch(getSubs());
  }, [dispatch]);

  const handleClick = async (e, price) => {
    e.preventDefault();

    const { data } = await axios.post(
      `https://yoga-motion-studio.herokuapp.com/api/subs/create-subscription/${user._id}`,
      {
        priceId: price.id,
      }
    );
    if (!data) {
      return <Spinner />;
    }
    window.open(data);
    console.log(user);
    console.log(data);
    console.log('plan clicked', price.id);
  };

  return (
    <>
      <div className="price-container">
        <div className="price-center">
          <div className="price-card">
            <div className="price-subscribe">
              <h2>Unlimited Yoga</h2>
              <p>Get access to Unlimited classes</p>

              <h2>
                $100.00
                <small className="text-muted fw-light">/mo</small>
              </h2>
              <a
                className="price-btn price-btn-block"
                href="https://buy.stripe.com/7sI5on9Kh7256YMdQR"
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubPage;
