import { useState } from 'react';
import './UpdatePassword.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { logout, updatePassword } from '../../features/auth/authSlice';

function UpdatePassword() {
  const { user } = useSelector((state) => state.auth);

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    if (!password || password.length < 6) {
      toast.error('Password must be at least 6 characters long');
    } else if (password !== password2) {
      toast.error('Passwords do not match');
    } else {
      dispatch(updatePassword({ ...user, password }));
      dispatch(logout());
      toast.success('Password updated!');
    }
  };
  return (
    <>
      <div className="update-password-form">
        <section className="heading">
          <p>Change your password</p>
        </section>

        <section className="form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password2">Confirm Password</label>
              <input
                type="password"
                name="password2"
                id="password2"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </div>

            <div className="form-group">
              <button className="update-password-btn">Update Password</button>
            </div>
          </form>
          <hr />
          <div className="">
            <Link className="cancel-update-password" to={'/profile'}>
              Cancel
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}

export default UpdatePassword;
