import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import eventReducer from '../features/events/eventSlice';
import bookingReducer from '../features/bookings/bookingslice';
import subscriptionReducer from '../features/subscriptions/subscriptionSlice';
import priceReducer from '../features/prices/priceSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    events: eventReducer,
    bookings: bookingReducer,
    subscriptions: subscriptionReducer,
    prices: priceReducer,
  },
});
