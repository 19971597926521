import axios from 'axios';

const API_URL = 'https://yoga-motion-studio.herokuapp.com/api/users/';

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData);

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};

// Get user ticket
const getUser = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      headers: { 'Access-Control-Allow-Origin': '*' },
    },
  };

  const response = await axios.get(API_URL + 'getMe', config);

  return response.data;
};

// update user
const updatePassword = async (user, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(API_URL + 'reset-password', user, config);

  return response.data;
};

// Logout user
const logout = () => localStorage.removeItem('user');

const authService = {
  register,
  logout,
  login,
  getUser,
  updatePassword,
};

export default authService;
