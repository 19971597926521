import { FaArrowCircleLeft, FaSignInAlt, FaUser } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './EventHeader.css';

function EventHeader() {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    navigate('/events');
  };
  return (
    <header className="event-header">
      <a
        className="event-logo "
        href="https://us02web.zoom.us/j/6584904715"
        rel="noreferrer"
        target="_blank"
      >
        <img
          className="event-logo-size"
          src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1664826494/zoom_ojouzx.png"
          alt=""
        />
      </a>

      <ul>
        {user ? (
          <>
            <li>
              <button className="event-btn" onClick={onLogout}>
                <FaArrowCircleLeft /> Back
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/login">
                <FaSignInAlt /> Login
              </Link>
            </li>

            <li>
              <Link to="/register">
                <FaUser /> Register
              </Link>
            </li>
          </>
        )}
      </ul>
    </header>
  );
}

export default EventHeader;
