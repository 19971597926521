import axios from 'axios';

const API_URL = 'https://yoga-motion-studio.herokuapp.com/api/events/';

// Create new ticket
const createEvent = async (eventData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, eventData, config);

  return response.data;
};

// Get Events
const getEvents = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

// Get single event
const getEvent = async (eventId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + eventId, config);

  return response.data;
};

// Close event
const closeEvent = async (eventId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL + eventId,
    { status: 'closed' },
    config
  );

  return response.data;
};

const eventService = {
  createEvent,
  getEvents,
  getEvent,
  closeEvent,
};

export default eventService;
