import React, { useEffect, useState } from 'react';
import './Event.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { closeEvent, getEvent } from '../../features/events/eventSlice';
import {
  createBooking,
  getBookings,
} from '../../features/bookings/bookingslice';

import EventHeader from '../../components/EventHeader/EventHeader';
import moment from 'moment';

function Event() {
  const [bookingText, setBookingText] = useState('');
  const { user } = useSelector((state) => state.auth);

  const { event, isLoading, isError, message } = useSelector(
    (state) => state.events
  );

  const { bookings } = useSelector((state) => state.bookings);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventId } = useParams();

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    dispatch(getEvent(eventId));
    dispatch(getBookings(eventId));
    // eslint-disable-next-line
  }, [isError, message, eventId]);

  // Create booking
  const onBookingSubmit = (e) => {
    e.preventDefault();
    dispatch(createBooking({ bookingText, eventId }));
  };

  // Close event
  const onEventClose = () => {
    dispatch(closeEvent(eventId));
    toast.success('Class Closed');
    navigate('/events');
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <h3>Something Went Wrong</h3>;
  }

  return (
    <>
      <div className="event-container">
        <div className="event-center">
          <EventHeader />
          <header className="event-heading">
            {user.isAdmin && (
              <h2>
                <span className={`status status-${event.status}`}>
                  {event.status}
                </span>
              </h2>
            )}

            <h1>{event.title}</h1>
            <p>{moment(event.dayTime).format('MMMM dddd Do').toString()}</p>
            <h2>{moment(event.dayTime).format('h:mm a').toString()}</h2>
            <p>{event.description}</p>
          </header>
          <div className="form-container">
            <form className="inline-bookings " onSubmit={onBookingSubmit}>
              <input
                name="bookingText"
                id="bookingText"
                className="form-control hidden-input"
                value={user.name}
                onChange={(e) => setBookingText(user.name)}
              />

              {bookings.length > 4 && <h2>No Spots Available</h2>}

              {bookings.length < 5 && (
                <button className="booking-btn" type="submit">
                  Book Now!
                </button>
              )}
            </form>
          </div>

          <p className="bold">{5 - bookings.length} Spots Left</p>
          <hr className="hr-space" />
          <div className="hr-space">
            {bookings ? (
              bookings.map((booking) => (
                <div key={booking._id} className="booking-list">
                  {booking.text === user.name && (
                    <p>
                      {booking.text}
                      {': '}
                      <Link
                        to={`/delete-booking/${booking._id}`}
                        className=" delete-link"
                      >
                        Cancel
                      </Link>
                    </p>
                  )}
                </div>
              ))
            ) : (
              <Spinner />
            )}

            {/* --------------------------------------------isAdmin Only ---------------------------------------- */}

            {user.isAdmin &&
              (bookings ? (
                bookings.map((booking) => (
                  <div key={booking._id} className="booking-list">
                    {
                      <p>
                        {booking.text}
                        {': '}
                        <Link
                          to={`/delete-booking/${booking._id}`}
                          className=" delete-link"
                        >
                          Cancel
                        </Link>
                      </p>
                    }
                  </div>
                ))
              ) : (
                <Spinner />
              ))}
          </div>
          {user.isAdmin && (
            <button onClick={onEventClose} className="booking-btn">
              Close Class
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Event;
