import React, { useEffect, useState } from 'react';
import './Profile.css';
import moment from 'moment';
import Modal from 'react-modal';

import logo from '../../logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import {
  getStripePortal,
  getSubscriptions,
} from '../../features/subscriptions/subscriptionSlice';
import { FaTimesCircle } from 'react-icons/fa';

const customStyles = {
  content: {
    padding: '2rem',
    borderRadius: '2rem',
    width: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
    backgroundColor: '#a5c9ca',
    boxShadow: `20px 20px 30px #000`,
  },
};

Modal.setAppElement('#root');

function Profile() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);

  // Open/close modal
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const manageSubscriptions = async () => {
    dispatch(getStripePortal());
  };

  return (
    <>
      <header className="profile-header">
        <h1>Hello, {user.name}</h1>
        <p className="email-p">Email:</p>
        <p className="margin-Bottom"> {user.email}</p>
        <Link
          className="change-password-link margin-Bottom"
          to={`/update-password`}
        >
          Change Password
        </Link>

        {user.isAdmin && <Link to={`/create-event`}>Create Event</Link>}

        <img src={logo} className="profile-logo" alt="logo" />
        {user.subscriptions.length ? (
          <Link onClick={openModal}>View Subscription</Link>
        ) : (
          <Link to={'/sub-page'}>Add a Subsription</Link>
        )}
      </header>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="View Subscriptions"
      >
        <h2>{user.name}'s Subscription</h2>
        <button className="modal-btn-close" onClick={closeModal}>
          <FaTimesCircle />
        </button>

        {user.subscriptions ? (
          user.subscriptions.map((sub) => (
            <div key={sub.id}>
              <section className="sub-section">
                <hr />
                <h4 className="fw-bold">{sub.plan.nickname}</h4>
                <h5>
                  Price:{' '}
                  {(sub.plan.amount / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: sub.plan.currency,
                  })}
                </h5>
                <p>Status: {sub.status}</p>

                {sub.default_payment_method === null ? (
                  <p>Card last 4 digit: No Card #</p>
                ) : (
                  <p>
                    Card last 4 digit: {sub.default_payment_method.card.last4}
                  </p>
                )}

                <p>
                  Current period end:{' '}
                  {moment(sub.current_period_end * 1000)
                    .format('dddd, MMMM Do YYYY h:mm:ss a')
                    .toString()}
                </p>
              </section>
              <button onClick={manageSubscriptions} className="manage-sub-btn">
                Manage Subsriptions
              </button>
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </Modal>
      <hr />
      <div></div>
    </>
  );
}

export default Profile;
