import React, { useState } from 'react';
import './NavBar.css';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FaSignInAlt,
  FaSignOutAlt,
  FaTimesCircle,
  FaUser,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from '../../features/auth/authSlice';

const customStyles = {
  content: {
    padding: '0',
    height: '88%',
    width: '75%',
    backgroundColor: '#a5c9ca',
    boxShadow: `20px 20px 30px #000`,
  },
};

Modal.setAppElement('#root');

function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/');
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Open/close modal
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  return (
    <>
      <AnimatePresence initial={false}>
        <div className="header">
          <div className="logo ">
            <Link to="/">
              <img
                className="logo-size"
                src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1663443299/logo_transparent_1_lbtsfx.png"
                alt=""
              />
            </Link>
          </div>
          <ul>
            {user ? (
              <>
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>
                  <Link to={'/profile'}>Profile</Link>
                </li>
                <li>
                  <Link to={'/events'}>Classes</Link>
                </li>
                <li>
                  <Link to={'/video-library'}>Videos</Link>
                </li>

                <li>
                  <Link to={'/logout'}>
                    <FaSignOutAlt /> Logout
                  </Link>
                </li>
                {/* <li>
              <a href="#faq">FAQ's</a>
            </li> */}
              </>
            ) : (
              <>
                <li>
                  <Link to="/login">
                    <FaSignInAlt /> Login
                  </Link>
                </li>

                <li>
                  <Link to="/register">
                    <FaUser /> Register
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* -------------------collapsed header--------------------------- */}
        <div className="header-collapsed">
          {!modalIsOpen && (
            <motion.button
              whileHover={{ scale: 1.4 }}
              whileTap={{ scale: 0.6 }}
              className="header-collapsed-btn"
              onClick={openModal}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z"
                  fill="#2c3333"
                />
                <path
                  d="M2 12.0322C2 11.4799 2.44772 11.0322 3 11.0322H21C21.5523 11.0322 22 11.4799 22 12.0322C22 12.5845 21.5523 13.0322 21 13.0322H3C2.44772 13.0322 2 12.5845 2 12.0322Z"
                  fill="#2c3333"
                />
                <path
                  d="M3 17.0645C2.44772 17.0645 2 17.5122 2 18.0645C2 18.6167 2.44772 19.0645 3 19.0645H21C21.5523 19.0645 22 18.6167 22 18.0645C22 17.5122 21.5523 17.0645 21 17.0645H3Z"
                  fill="#2c3333"
                />
              </svg>
            </motion.button>
          )}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Menu"
          >
            <>
              <div className="menu-container">
                <header className="menu-header">
                  <div className="logo ">
                    <Link to="/">
                      <img
                        className="logo-size"
                        src="https://res.cloudinary.com/dkiqkafgo/image/upload/v1663443299/logo_transparent_1_lbtsfx.png"
                        alt=""
                      />
                    </Link>
                  </div>
                </header>
                <button className="nav-modal-btn-close" onClick={closeModal}>
                  <FaTimesCircle />
                </button>
                <main className="menu-main">
                  <ul>
                    {user ? (
                      <>
                        <li>
                          <h2>
                            <Link
                              to={'/'}
                              onClick={closeModal}
                              className="menu-link"
                            >
                              Home
                            </Link>
                          </h2>
                        </li>
                        <li>
                          <h2>
                            <Link
                              to={'/profile'}
                              onClick={closeModal}
                              className="menu-link"
                            >
                              Profile
                            </Link>
                          </h2>
                        </li>
                        <li>
                          <h2>
                            <Link
                              to={'/events'}
                              onClick={closeModal}
                              className="menu-link"
                            >
                              Classes
                            </Link>
                          </h2>
                        </li>
                        <li>
                          <h2>
                            <Link
                              to={'/video-library'}
                              onClick={closeModal}
                              className="menu-link"
                            >
                              Videos
                            </Link>
                          </h2>
                        </li>

                        <li>
                          <h2
                            className="menu-link show-pointer"
                            onClick={onLogout}
                          >
                            Logout
                          </h2>
                        </li>
                        {/* <li>
                      <h2>
                        <a
                          href="#faq"
                          onClick={closeModal}
                          className="menu-link"
                        >
                          FAQ's
                        </a>
                      </h2>
                    </li> */}
                      </>
                    ) : (
                      <>
                        <li>
                          <h2>
                            <Link
                              className="menu-link"
                              to="/login"
                              onClick={closeModal}
                            >
                              <FaSignInAlt /> Login
                            </Link>
                          </h2>
                        </li>

                        <li>
                          <h2>
                            <Link
                              className="menu-link"
                              to="/register"
                              onClick={closeModal}
                            >
                              <FaUser /> Register
                            </Link>
                          </h2>
                        </li>
                      </>
                    )}
                  </ul>
                </main>
              </div>
            </>
          </Modal>
        </div>
        {/* -------------------collapsed header end--------------------------- */}
      </AnimatePresence>
    </>
  );
}

export default NavBar;
