import React from 'react';
import { Link } from 'react-router-dom';
import './StripeConfirmationPage.css';

function StripeConfirmationPage() {
  return (
    <div className="stripe-success">
      <h1>Success!</h1>
      <h2>Thank you for puchasing a membership</h2>
      <Link className="stripe-success-link" to={'/profile'}>
        View Profile
      </Link>
      <p>Or</p>
      <Link className="stripe-success-link" to={'/events'}>
        Book a Class
      </Link>
    </div>
  );
}

export default StripeConfirmationPage;
