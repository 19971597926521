import React from 'react';
import './ConfirmLogout.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../../features/auth/authSlice';

function ConfrirmLogout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/');
  };

  const onCancel = () => {
    dispatch(reset());
    navigate('/profile');
  };

  return (
    <>
      <div className="container">
        <div className="center">
          <button className="confirm-logout-btn " onClick={onLogout}>
            Logout
          </button>
          <hr />
          <button className="confirm-logout-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfrirmLogout;
