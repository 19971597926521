import React from 'react';
import './EventItem.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

function EventItem({ event }) {
  return (
    <AnimatePresence initial={false}>
      <motion.div
        initial={{ x: -500, opacity: 0 }}
        whileInView={{
          x: 0,
          opacity: 1,
          transition: { type: 'spring', bounce: 0.4, duration: 1 },
        }}
        className="event-item"
      >
        <div className="row">
          <div className="column-1">
            <h2 className="month">
              {moment(event.dayTime).format(' MMMM').toString()}
            </h2>
            <h2 className="day-name">
              {moment(event.dayTime).format('dddd').toString()}
            </h2>
            <p className="day-number">
              {moment(event.dayTime).format('Do ').toString()}
            </p>
            <p className="time">
              {moment(event.dayTime).format('h:mm a').toString()}
            </p>
          </div>
          <div className="column-2">
            <h2>{event.title}</h2>
            <p className="event-item-description">
              {event.description.length > 50
                ? `${event.description.slice(0, 50)}...`
                : event.description.name}
            </p>
            <div className="center-div">
              <Link to={`/event/${event._id}`} className="event-item-btn ">
                View
              </Link>
            </div>
          </div>
        </div>
        {/* <div>{new Date(event.createdAt).toLocaleString('en-US')}</div>
      <div>{event.title}</div>
      <Link to={`/event/${event._id}`} className="event-item-btn ">
        View
      </Link> */}
      </motion.div>
    </AnimatePresence>
  );
}

export default EventItem;
