import React, { useState } from 'react';
import './Events.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventItem from '../../components/EventItem/EventItem';
import Spinner from '../../components/Spinner/Spinner';
import { getEvents, reset } from '../../features/events/eventSlice';

import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

function Events() {
  const { user } = useSelector((state) => state.auth);
  const { events, isLoading, isSuccess } = useSelector((state) => state.events);

  const [activeStatus, setActiveStatus] = useState('open');

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (isSuccess) {
        dispatch(reset());
      }
    };
  }, [dispatch, isSuccess]);

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <AnimatePresence initial={false}>
        <div className="events-container">
          {user.isAdmin && (
            <div className="events-headings">
              <div className="status-btn">
                <button
                  className="status-query-btn"
                  onClick={() => setActiveStatus('closed')}
                >
                  Closed
                </button>

                <button
                  className="status-query-btn"
                  onClick={() => setActiveStatus('open')}
                >
                  Open
                </button>
              </div>
              <div className="create-class">
                <Link className="create-class-link" to={`/create-event`}>
                  Create Event
                </Link>
              </div>
            </div>
          )}

          <div className="events">
            {events
              .filter((event) => event.status.includes(activeStatus))
              .sort((a, b) => (a.dayTime > b.dayTime ? 1 : -1))
              .map((event) => (
                <EventItem key={event._id} event={event} />
              ))}
          </div>
        </div>
      </AnimatePresence>
    </>
  );
}

export default Events;
