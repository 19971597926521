import React from 'react';
import './ConfirmBookingDelete.css';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { deleteBooking, reset } from '../../features/bookings/bookingslice';

function ConfirmBookingDelete() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bookingId } = useParams();

  const onBookingDelete = () => {
    dispatch(deleteBooking(bookingId));
    navigate('/events');
    toast.success('Booking Successfully Deleted');
  };

  const onCancel = () => {
    dispatch(reset());
    navigate('/events');
  };

  return (
    <>
      <div className="container">
        <div className="center">
          <button className="delete-booking-btn " onClick={onBookingDelete}>
            Delete
          </button>
          <hr />
          <button className="delete-booking-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmBookingDelete;
