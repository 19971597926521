import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import PrivateRoute from './components/PrivateRoute';
import ConfrirmLogout from './pages/ConfirmLogout/ConfrirmLogout';
import ConfirmBookingDelete from './pages/ConfirnBookingDelete/ConfirmBookingDelete';
import CreateEvent from './pages/CreateEvent/CreateEvent';
import Event from './pages/Event/Event';
import Events from './pages/Events/Events';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register/Register';
import StripeCancelPage from './pages/StripeCancelPage/StripeCancelPage';
import StripeConfirmationPage from './pages/StripeConfrmationPage/StripeConfirmationPage';
//import StripeSuccessPage from './pages/StripeSuccessPage/StripeSuccessPage';
import SubPage from './pages/SubPage/SubPage';
import UpdatePassword from './pages/UpdatePassword/UpdatePassword';
import VideoLibrary from './pages/VideoLibrary/VideoLibrary';

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<ConfrirmLogout />} />
            <Route path="/stripe/cancel" element={<StripeCancelPage />} />
            <Route
              path="/stripe/success"
              element={<StripeConfirmationPage />}
            />

            <Route path="/profile" element={<PrivateRoute />}>
              <Route path="/profile" element={<Profile />} />
            </Route>

            <Route path="/update-password" element={<PrivateRoute />}>
              <Route path="/update-password" element={<UpdatePassword />} />
            </Route>

            <Route path="/sub-page" element={<PrivateRoute />}>
              <Route path="/sub-page" element={<SubPage />} />
            </Route>

            <Route path="/video-library" element={<PrivateRoute />}>
              <Route path="/video-library" element={<VideoLibrary />} />
            </Route>

            <Route path="/create-event" element={<PrivateRoute />}>
              <Route path="/create-event" element={<CreateEvent />} />
            </Route>

            <Route path="/events" element={<PrivateRoute />}>
              <Route path="/events" element={<Events />} />
            </Route>

            <Route path="/event/:eventId" element={<PrivateRoute />}>
              <Route path="/event/:eventId" element={<Event />} />
            </Route>

            <Route path="/delete-booking/:bookingId" element={<PrivateRoute />}>
              <Route
                path="/delete-booking/:bookingId"
                element={<ConfirmBookingDelete />}
              />
            </Route>
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
